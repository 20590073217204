import React from 'react'
import { Link } from 'gatsby'

import arrowForward from '../images/baseline_arrow_forward_white_36dp.png'
import '../templates/seminar.scss'
import { seminarDate } from '../lib/months'
import Flex from './flex'

export default function SeminarComponent ({ seminar, month }) {
  const date = seminarDate(seminar)
  const dayOfMonth = date.getDate()
  const dateStr = `${month} ${dayOfMonth}`

  return (
    <Flex className='seminar'>
      <div className='seminar-date fill-row'>
        <div className='seminar-date-border' />
        {dateStr}
      </div>
      <div className='container-flex-row seminar-title-loc-container fill-row'>
        <div className='seminar-title'>
          <Link to={seminar.path} style={{ color: '#000000' }}>
            {seminar.title}
          </Link>
          <div className='seminar-loc'>{seminar.location_name}</div>
        </div>
        <Link
          to={seminar.path}
          className='fill-row'
          style={{ backgroundColor: '#005BAA', height: '36px', width: '36px' }}
        >
          <img src={arrowForward} alt='arrow-forward' height='36' width='36' />
        </Link>
      </div>
    </Flex>
  )
}
