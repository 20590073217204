import React from 'react'

export default function ContactFooter () {
  return (
    <div className='contact-container' id='contact'>
      <div className='contact-content'>
        <div style={{ letterSpacing: '1px' }}>
          <span className='material-icons'>phone</span>
          &nbsp;(443)&nbsp;413-7866
        </div>
        <div style={{ letterSpacing: '1px' }}>
          <span className='material-icons'> email</span>
          &nbsp;info@coachmillerseminars.com
        </div>
      </div>
    </div>
  )
}
