import React from 'react'

import './index-section.scss'

export default function Section ({ children, fullwidth = false }) {
  let style = {}
  if (!fullwidth) {
    style = { maxWidth: '90%' }
  }

  return (
    <section className='index-section' style={style}>
      {children}
    </section>
  )
}
