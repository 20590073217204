import React from 'react'
import { Link } from 'gatsby'

import dumbbell from '../images/dumbbell.png'
import remoteImg from '../images/remote.png'
import squatImg from '../images/squat-rack.png'
import Flex from './flex'

export default function HomeTraining () {
  return (
    <>
      <h1 id='training-plans' className='section-title'>Training Plans</h1>
      <Flex className='training-plans-container'>
        <div className='cards-services'>
          <div className='flex flex-col items-center'>
            <div className='cards-container'>
              <div>Custom Training</div>
              <div>Templates</div>
            </div>
            <a
              href='https://app.coachmillerseminars.com'
              target='_blank'
              rel='noopener noreferrer'
              className='card-btn'
            >
              Get Started
            </a>
          </div>
          <img className='img-cards' src={dumbbell} alt='Custom Training Plan Logo' height='108' width='180' />
        </div>
        <div className='cards-services'>
          <div className='flex flex-col items-center'>
            <div className='cards-container'>
              <div>Miller Squat</div>
              <div>Program</div>
            </div>
            <Link to='/miller-squat-program' className='card-btn'>Buy NOW</Link>
          </div>
          <img className='img-cards' src={squatImg} alt='Miller Squat Program Logo' height='132' width='180' />
        </div>
        <div className='cards-services'>
          <div className='flex flex-col items-center'>
            <div className='cards-container'>
              <div>Remote</div>
              <div>Coaching</div>
            </div>
            <Link to='/remote-coaching' className='card-btn'>Contact Me</Link>
          </div>
          <img className='img-cards' src={remoteImg} alt='Remote Coaching Logo' height='129' width='180' />
        </div>
      </Flex>
    </>
  )
}
