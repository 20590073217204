import React from 'react'

export default function AboutMe () {
  return (
    <>
      <p>
        An International USA Weightlifting Coach with 35 years of experience,
        Coach Miller has produced multiple State, National and Team USA World
        &amp; Pan AM athletes. He’s travelled as Coach and Team Leader on two
        International assignments for USA Weightlifting and supports the Olympic
        goals of the United States - "...bringing our country back into the
        international spotlight."
      </p>
      <p>
        Coach Miller also serves both USA Weightlifting and the International
        Weightlifting Federation as a Category 1 Referee, the highest level.
        He’s an active member of NSCA as a Certified Strength &amp; Conditioning
        Specialist and participates in the Special Interest Group for
        Weightlifting.
      </p>
      <p>
        While competing nationally, David Miller gained his coaching education
        through the International Level and began, in 1997 leading USA
        Weightlifting Coaching Certification Courses. Twenty years ago the
        majority of attendees to these certifications were Collegiate Division I
        or NFL/MLB/NBA Strength &amp; Conditioning Coaches. Today, more
        individuals and CrossFit Coaches attend these courses. Together with the
        thousands Coach Miller has had the opportunity to certify through USA
        Weightlifting, he’s spent a great amount of time teaching young coaches
        how to help spread the love of the sport and it’s direct application to
        improving sports performance. In his early athletic career as a
        Weightlifter, Coach Miller experienced an incredible increase in his own
        200M sprint speed. He learned first hand how the emphasis on leg and hip
        training from Olympic-Style Weightlifting, not to mention the upper
        body/core stability gained from holding heavy weights overhead is the
        most efficient method to maximize everyone’s strength training regiment.
      </p>
    </>
  )
}
