import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SeminarList from '../components/seminar-list'

import './index.scss'
import heroImg from '../images/home-hero.png'

import Contact from '../components/home-contact'
import Quote from '../components/home-quote'
import About from '../components/home-about'
import Section from '../components/index-section'
import HomeTraining from '../components/home-training'
import { seminarDate } from '../lib/months'

export default function IndexPage ({ data }) {
  const events = data.allSeminarsYaml.edges.filter(n => seminarDate(n.node) > new Date())

  return (
    <Layout>
      <SEO title='Home' keywords={['david miller', 'weightlifting', 'seminar', 'strength', 'squat', 'coaching']} />
      <div className='headline-section'>
        <img
          src={heroImg}
          alt='Coach Miller Seminars Logo'
          height='299'
          width='630'
          className='img-headline'
        />
        <span className='headline-quot'>
          &quot;Delivering highly specialized individual
          Coaching to all athletes at every level of performance.&quot;
        </span>
      </div>
      <div className='home-page-container'>
        <Section>
          <h1 id='seminars' className='section-title'>Seminars</h1>
          <SeminarList seminars={events} />
        </Section>
        <Section>
          <HomeTraining />
        </Section>
        <Section fullwidth>
          <Quote />
        </Section>
        <Section>
          <About />
        </Section>
        <Section fullwidth>
          <Contact />
        </Section>
      </div>
    </Layout>
  )
}

export const indexQuery = graphql`
  query IndexQuery {
    allSeminarsYaml(limit: 20, sort: {
      fields: [date],
      order: ASC
    }) {
      edges {
        node {
          id
          path
          title
          date
          location_name
          seminar {
            seminar_id
            date
          }
        }
      }
    }
  }
`
