import React from 'react'

import profileImg from '../images/david_miller.png'
import AboutMe from './about-me'
import Flex from './flex'

export default function About () {
  return (
    <>
      <h1 className='section-title' id='about'>About</h1>
      <Flex className='about-container'>
        <div className='container-flex-column about-picture-name-container fill-row'>
          <img src={profileImg} height='200' width='200' alt='David Miller' />
          <div style={{ fontWeight: 'bold', fontSize: '30px' }}>David Miller</div>
        </div>
        <div className='about-text-conteiner fill-row'>
          <AboutMe />
        </div>
      </Flex>
    </>
  )
}
