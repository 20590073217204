import React from 'react'
import Seminar from './seminar'
import { getMonth } from '../lib/months'

export default function SeminarList ({ seminars }) {
  if (!seminars.length) {
    return (
      <div className='container-flex-column'>
        Keeping everyone’s health and safety our paramount concern during this COVID-19 pandemic the Weightlifting Seminar Series for 2021 is going to be re-organized. Stay tuned here for updates.
      </div>
    )
  }
  return (
    <div className='container-flex-column'>
      {seminars.map(({ node: s }) => (
        <Seminar key={s.id} seminar={s} month={getMonth(new Date(s.date))} />
      ))}
    </div>
  )
}
