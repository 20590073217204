import React from 'react'
import quote from '../images/baseline_format_quote_black_48dp.png'

import './home-quote.scss'

export default function Quote () {
  return (
    <div className='quote-container'>
      <img src={quote} alt='quote icon' height='48' width='48' />
      <p className='quote-text'>
        In addition to being a very experienced coach who has working with many top athletes, David
        Miller is one our most active coaching course instructors. No doubt those who train with him
        will come away having learned things that will really improve their lifting.
      </p>
      <p style={{ color: '#575757', fontWeight: 'bold' }}>Artie Drechsler</p>
      <p style={{
        color: '#575757', fontWeight: 'lighter', letterSpacing: '1px', margin: '0'
      }}
      >
        USAW International Coach &amp; Author of The Weightlifting Encyclopedia
      </p>
    </div>
  )
}
